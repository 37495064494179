<template>
  <BaseModal :name="idModal" :idModal="idModal" size="lg" title="Detalhes" :hideFooter="true">
    <div class="container">
      <div class="lead-info" v-if="statusLeadResponse">
        <div class="lead-header" style="display: flex;">
          <div>
            <div class="lead-picture" :style="leadPicture">
              {{ leadPicture ? '' : getMetaInitialLead(lead.name) }}
            </div>
            <div>
              <b>{{ lead.name }}</b>
              <span>{{ lead.email }}</span>
              <span>{{ lead.phone }}</span>
            </div>
          </div>
          <button
            class="btn-lead btn-view-lead"
            @click="openPageLead"
          >
            Visualizar Lead
          </button>
        </div>
        <div class="lead-adds" v-if="lead && lead.metas && lead.metas[0]">
          <div
            v-for="adicional of lead.metas.filter(
              meta =>
                meta.meta_key != 'photo-lead' &&
                meta.meta_key != 'pipeline_view'
            )"
            :key="adicional.id"
          >
            <b class="title-item-modal">{{ adicional.meta_key }}</b>
            <span class="info-leadgen">{{ adicional.meta_value }}</span>
          </div>
        </div>
      </div>
      <span style="color: var(--gray04); margin: 5px 0 0 5px !important; font-size: 12px;">Lead processado em {{ updatedItem }}</span>
      <div class="row col-12" v-if="!statusLeadResponse">
        <div class="mt-2 row col-12">
          <div class="col-12 mt-3">
            <p class="mb-2 title-item-modal">{{ messageError }}</p>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";

import LeadService from "@/services/resources/LeadService.js";
const LeadServiceApi = LeadService.build();
import LeadAdLeadService from "@/services/resources/LeadAdLeadService.js";
const LeadAdLeadServiceApi = LeadAdLeadService.build();

export default {
  name: "ModalViewLeadgen",
  props: {
    idModal: {
      required: true,
    },
    leadgen: {
      required: false,
    },
    updatedItem: {
      required: false,
    }
  },
  data: () => ({
    status: "",
    lead: { adicionais: [] },
    statusLeadResponse: true,
    messageError: "",
    nameBtnError: "",
  }),
  computed: {
    leadPicture() {
      if (this.lead && this.lead.metas && this.lead.metas.find(meta => meta.meta_key == 'photo-lead')) {
        return `background-image: url(${this.lead.metas.find(meta => meta.meta_key == 'photo-lead').meta_value}); background-color: transparent;`
      } return ''
    },
  },
  watch: {
    leadgen: async function () {
      let responseRaw = null;
      if (this.leadgen.status === "queued") {
        responseRaw = { status: false, erro: "Lead em preocessamento" };
      } else if (this.leadgen.raw_response) {
        responseRaw = JSON.parse(this.leadgen.raw_response);
      } else {
        responseRaw = { status: false };
      }

      if (!responseRaw.status && !this.leadgen.lead_id) {
        this.statusLeadResponse = responseRaw.status;
        this.messageError = responseRaw.erro;
      } else {
        this.lead = await LeadServiceApi.read(this.leadgen.lead_id);
        this.statusLeadResponse = true;

      }

      this.leadgen.created_at = moment(this.leadgen.created_at).format(
        "DD/MM/YYYY HH:MM"
      );

      switch (this.leadgen.status) {
        case "success":
          this.status = "Lead cadastrado";
          break;
        case "token_error":
          if (
            this.leadgen.status === "token_error" &&
            this.leadgen.fanpage_status === "token_error"
          ) {
            this.nameBtnError = "Atualize o token da fanpage primeiro";
          } else {
            this.nameBtnError = "Reprocessar Lead";
          }

          this.status = "Lead com erro de token";
          break;
        case "error":
          this.nameBtnError = "Reprocessar Lead";
          this.status = "Lead com erro";
          break;
        default:
          this.status = "Em processamento";
          this.nameBtnError = "Lead esta em processamento";
      }
    },
  },
  methods: {
    openPageLead() {
      var path = `${process.env.BASE_URL}${this.$route.params.dynamicRoute}/lead/${this.lead.id}`;
      window.open(path);
    },
    restartData() {
      (this.status = ""),
        (this.lead = { adicionais: [] }),
        (this.statusLeadResponse = true),
        (this.messageError = ""),
        (this.nameBtnError = "");
    },
    getMetaInitialLead(user) {
      if (user) {
        var name = user.split(" ")[0];
        var sobrenome = user.split(" ")[1] ?? undefined;
        if (!name && !sobrenome) {
          return "";
        }
        if (!sobrenome) {
          return name.substring(0, 2);
        }
        return name[0] + sobrenome[0];
      }
      return '??';
    },
  },
};
</script>

<style scoped lang="scss">
.lead-info {
  border: 1px solid var(--white-medium);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  transition: .3s;
}
.lead-header {
  display: grid;
  width: 100%;
  grid-template-columns: calc(100% - 189px) 174px;
  gap: 15px;
  align-items: center;
  > div:first-child {
    display: grid;
    width: 100%;
    grid-template-columns: 60px calc(100% - 75px);
    gap: 15px;
    > div:last-child {
      font-size: 14px;
      min-height: 60px;
      display: grid;
      align-content: center;
      *{
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      b {
        font-size: 16px;
      }
    }
  }
}
.lead-picture {
  border-radius: 100%;
  overflow: hidden;
  min-width: 60px;
  width: 60px;
  min-height: 60px;
  height: 60px;
  background-color: var(--greenn);
  font-size: 27px;
  display: flex;
  color: white;     
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background-size: cover;
  background-position: center;
}
.lead-adds {
  display: grid;
  gap: 10px;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  margin-top: 15px;
  max-height: 275px;
  overflow: hidden auto;
  > div {
    border: 1px solid var(--white-medium);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    display: grid;
    gap: 5px;
    background-color: white;
    * {
      display: inline-block;
      line-height: 1.2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      max-width: 100%;
      width: 100%;
    }
  }
}
.status-success {
  color: var(--greenn) !important;
  font-weight: 600;
}

.status-queued {
  color: var(--orange) !important;
  font-weight: 600;
}

.status-error,
.status-token_error {
  color: red !important;
  font-weight: 600;
}

.info-leadgen {
  font-size: 13px;
  line-height: 16px;
  color: #8c8a97;
}
.btn-reprocess-lead {
  background-color: var(--red);
}

.btn-lead {
  border: none;
  width: 175px;
  min-width: 175px;
  height: 45px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
}
.btn-view-lead {
  background: var(--greenn);
  transition: .3s;
  &:hover {
    transform: scale(1.05);
  }
}

#icon-close {
  cursor: pointer;
}

.info-status-leadgen {
  font-weight: 600 !important;
  color: var(--greenn) !important;
}

.title-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.title-item-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: var(--gray01);
}
</style>
