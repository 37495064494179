<template>
  <div>
    <b-container fluid class="p-0 container">
      <div class="content-initial">
        <div class="content-leadad">
          <slot />
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Content",
  computed: {
    ...mapGetters(["getLoading"]),
  },
};
</script>

<style>
.content-initial {
  display: flex;
}

.content-leadad {
  width: 100%;
}

.content {
  padding-top: 35px;
}
</style>
