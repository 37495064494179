<template>
<div>
  <Content>
      <HistoricForLeadAd/>
  </Content>
  </div>

</template>
<script>
import BaseView from '@/template/BaseView.vue';
import Content from "@/components/LeadAd/Content.vue";
import HistoricForLeadAd from "@/components/LeadAd/HistoricForLeadAd.vue";

export default {
  components: {
    BaseView,
    Content,
    HistoricForLeadAd
  },
};
</script>